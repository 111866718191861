/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NarrowBanner from '../components/NarrowBanner'
import ManWithHeadset from '../images/people/man-with-headset.jpg'
import ManWithHeadsetMobile from '../images/people/man-with-headset-mobile.jpg'
import seo from '../images/illustrations/seo.png'
import ppc from '../images/illustrations/ppc.png'
import createImage from '../utils/createImage'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Billboard,
  Column,
  Columns,
  Image,
  LinkButton,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940

  const SeoIcon = createImage(
    [seo],
    isMobile ? 214 : 300,
    isMobile ? 87 : 127,
    'fixed'
  )
  const PpcIcon = createImage(
    [ppc],
    isMobile ? 214 : 300,
    isMobile ? 87 : 127,
    'fixed'
  )

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Performance Marketing Company | Clearlink',
          description:
            'If you’re looking for a performance marketing partnership that delivers, contact the team at Clearlink today! Learn about our SEO, PPC, and other capabilities!',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          variant="full"
          className="no-padding-bottom-mobile"
          image={
            <img
              src={ManWithHeadset}
              alt="a customer service representative wearing glasses and a headset"
            />
          }
          mobileImage={
            <img
              src={ManWithHeadsetMobile}
              alt="a customer service representative wearing glasses and a headset"
            />
          }
          mainContent={
            <>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  A performance marketing partnership that delivers
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography className="mb-32">
                  Our unique business model emphasizes performance over
                  everything else, which means we are highly strategic and
                  efficient in how we operate. We’ll find the customers for you
                  and drive the metrics that will dramatically grow your
                  business.
                </Typography>
                <LinkButton
                  variant="hero"
                  to="#see-what-we-mean"
                  color="primary"
                >
                  See what we mean{' '}
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent backgroundColor="white" id="see-what-we-mean">
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column
                image={<Image data={SeoIcon} />}
                className="left-aligned-image no-content-padding-left"
              >
                <Typography variant="h3">
                  Organic growth that lasts
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Increase your brand’s visibility and reputation with our
                  data-driven insights and expertise. Our search engine
                  optimization experts customize their approach to your unique
                  business needs and consider every opportunity to cultivate
                  sustainable growth.
                </Typography>
              </Column>
              <Column
                image={<Image data={PpcIcon} />}
                className="left-aligned-image no-content-padding-left"
              >
                <Typography variant="h3">
                  Clicks that convert
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Our paid media team proactively creates and manages the
                  campaigns that get results—keeping costs low and conversions
                  high. We’ll coordinate with you to find the most efficient
                  ways to secure new customers that last.
                </Typography>
              </Column>
            </Columns>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h2" className="text-center">
              More of what makes us tick
              <span className="blue-punctuation-large">.</span>
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column>
                <Typography variant="h4">
                  Dedicated Sales Team: Close the deal
                </Typography>
                <Typography variant="body">
                  Seamlessly integrating our marketing and internal sales teams
                  means greater insight into customer behavior and greater
                  control over the user experience—allowing us to track and
                  optimize conversions online, via chat, or over the phone.
                </Typography>
                <LinkButton to="/partner-with-us" color="primary">
                  Start selling with us
                </LinkButton>
              </Column>
              <Column>
                <Typography variant="h4">
                  Automation: Marketing on autopilot
                </Typography>
                <Typography variant="body">
                  From personalized email campaigns to dynamic remarketing
                  efforts that reengage potential customers, our automated
                  marketing services ensure that every lead is maximized and no
                  users fall through the cracks.
                </Typography>
                <LinkButton to="/partner-with-us" color="primary">
                  Start automating
                </LinkButton>
              </Column>
              <Column>
                <Typography variant="h4">
                  SocialX: Give them something to talk about
                </Typography>
                <Typography variant="body">
                  Our SocialX team does the work for you, monitoring your online
                  reputation and engaging with repeat customers and new buyers
                  alike to increase awareness and positive brand sentiment. We
                  offer a suite of services designed to boost your standing in
                  the social space.
                </Typography>
                <LinkButton to="/socialx/services" color="primary">
                  See how it's done
                </LinkButton>
              </Column>
            </Columns>
          </div>
        </VariableContent>

        <NarrowBanner
          backgroundColor="#000000"
          heading={
            <div
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h5" className="s2">
                Let's grow together
              </Typography>
            </div>
          }
          button={
            <LinkButton
              to="/partner-with-us"
              color="primary"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Partner with us
            </LinkButton>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
